/* base */
import React from "react";
import axios from "axios";
import compressJS from "compress.js";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { useTitle, useWindowSize } from "react-use";

/* assets */
import { colors } from "theme/colors";

/* components */
import { Loader } from "icons/Loader";
import { CloseIcon } from "icons/Close";
import { Module } from "components/module";
import { Footer } from "components/footer";
import MultiSelect from "components/multiselect";
import {
  Form,
  Title,
  Label,
  Subtitle,
  InputField,
  SelectField,
  FormContainer,
  Row,
  InputRadioField,
  LanguageSelector,
  SubmitBtn,
  Message,
  TextAreaField,
  AddBtn,
  Grid,
  Loading,
} from "modules/sign-up/styles";

const FORM_ACTION = "https://mmc-api.vercel.app/participate";
const UPLOAD_PHOTO_ACTION = "https://mmc-api.vercel.app/upload-photos";
// const FORM_ACTION = "http://localhost:5000/participate";
// const UPLOAD_PHOTO_ACTION = "http://localhost:5000/upload-photos";

const RECAPTCHA_KEY = "6Ldj7dwZAAAAAIuvTcDuRBwd0XUSQsYOm83u9lgp";

const texts = {
  en: {
    title: "Participation Form",
    description1:
      "Whether you’re a musician of any level (a professional, an amateur, a student) - regardless of age, gender, culture and music genre - you can get involved in Make Music Day 2024 individually or in groups.",
    description2:
      "The festival worldwide is free for everyone and relies on volunteering.",
    description3: "Participations are non-profit.",
    description4:
      "This year, due to restrictive measures to limit the spread of the coronavirus, the festival will be held in the form of individual events. Also, due to the uncertainty of the pandemic restrictions, there is a possibility that the festival will be held either in person, online, or a combination of both.",
    description5: "The deadline for applications is April 13, 2024.",
    description6:
      "For more information contact us at: info@makemusiccyprus.org or at 97-839385.",
    subtitle1: "Contact details",
    subtitle2: "Participation details",
    subtitleSocials: "Social Media Accounts",
    fullname: "Full Name",
    email: "Email",
    email2: "Confirm Email",
    tel: "Contact Phone",
    age: "I am over 18 years old",
    prevParticipation:
      "Have you ever participated in the Make Music Cyprus festival",
    hear: "Where did you hear about Make Music Cyprus Festival? (you can choose more than one answer)",
    hearOptions: [
      { name: "Social media", value: "Social media" },
      { name: "TV spot", value: "TV spot" },
      { name: "Radio spot/announcement", value: "Radio spot/announcement" },
      { name: "Website", value: "Website" },
      { name: "Poster", value: "Poster" },
      {
        name: "From a previous participant",
        value: "From a previous participant",
      },
      { name: "From a volunteer", value: "From a volunteer" },
      { name: "From a friend", value: "From a friend" },
      { name: "From a previous festival", value: "From a previous festival" },
      { name: "Other", value: "Other" },
    ],
    participationName:
      "Participation Name (as you would like it to be listed in the line-up & provide it in both English and Greek, if applicable)",
    orgName: "Name of organisation, school, conservatory (if applicable)",
    city: "City of Residence",
    genre: "Music Genre",
    applies: "Choose what applies:",
    participants: "Names of Participants and musical instruments:",
    participantDescription:
      "Description of Participation (small bio and few words about your appearance at the festival):",
    socialMediaAccounts:
      "Your Social Media Accounts (FB, Instagram, YouTube, Sound Cloud, Website, κ.α.)",
    facebookAccount: "Facebook Account(s)",
    instagramAccount: "Instagram Account(s)",
    youtubeAccount: "YouTube Account(s)",
    otherAccount: "Other Social Media Account(s) or Website",
    duration: "Duration of Music Program",
    availability: "Availability Hours (You can choose more than one answer)",
    availabilityFrom: "Availability Hours - From",
    availabilityTo: "Availability Hours - To",
    availabilityNote: "Please select availability of at least 2 hours.",
    electricSupply: "I need electric supply",
    needEquipment:
      "Please list what equipment you need (e.g. guitar/bass amplifier, monitors, microphones, instruments, music stands etc.):",
    stands: "I need music stands",
    support:
      "I am willing to bring my own equipment and with this way to support the non-profit character of the festival.",
    supportEquipment: "Please specify what equipment you are willing to bring.",
    online: "If the festival is online, would you still like to participate?",
    online2:
      "If you answered yes to the above, how do you prefer your participation to be?",
    notes: "Is there anything else you would like to share with us?",
    photoConsent:
      "I consent to photography, filming, live broadcast as well as the publication of this material. For reasons of compliance with Regulation (EU) 2016/679 of the European Parliament, on the protection of individuals with regard to the processing of personal data.",
    accept:
      "I agree to receive emails and phone texts regarding important updates or notifications regarding my application.",
    notifications:
      "I would like to receive notifications about Make Music Cyprus events and activities.",
    yes: "YES",
    no: "NO",
    liveStreaming: "Live streaming",
    premadeVideo: "Premade video",
    min: "minutes",
    solo: "Solo",
    duet: "Duet",
    band: "Band - Music Ensemble - Orchestra",
    choir: "Vocal Ensemble - Choir",
    dance: "Dance Ensemble",
    photoUpload:
      "Upload 2 photos for the promotion of your participation. (jpg)",
    submitInfo:
      "After you submit the form, we will contact you via email address. If you don't receive an email, please contact us.",
  },
  gr: {
    title: "Αίτηση Συμμετοχής",
    description1:
      "Εάν είστε μουσικός, οποιουδήποτε επιπέδου (επαγγελματίας, ερασιτέχνης, μαθητευόμενος) - ανεξαρτήτως ηλικίας, φύλου, εθνικότητας και μουσικού είδους - μπορείτε να συμμετάσχετε στο φεστιβάλ Make Music Cyprus 2024 ατομικά ή ομαδικά.",
    description2:
      "Το φεστιβάλ παγκοσμίως είναι δωρεάν για όλους και στηρίζεται στον εθελοντισμό.",
    description3: "Όλες οι συμμετοχές είναι αφιλοκερδώς.",
    description4:
      "Φέτος, λόγω των περιοριστικών μέτρων για περιορισμό εξάπλωσης του κορονοϊού, το φεστιβάλ θα διεξαχθεί υπό μορφή μεμονωμένων εκδηλώσεων. Επίσης, λόγω της αβεβαιότητας των περιοριστικών μέτρων της πανδημίας, υπάρχει η πιθανότητα το φεστιβάλ να διεξαχθεί είτε με φυσική παρουσία, είτε διαδικτυακά, είτε συνδυασμός και των δύο.",
    description5:
      "Η τελευταία ημερομηνία υποβολής της αίτησης είναι η 13η Απριλίου 2024.",
    description6:
      "Για περισσότερες πληροφορίες επικοινωνήστε μαζί μας στο: info@makemusiccyprus.org ή στο τηλέφωνο 97-839385.",
    subtitle1: "Στοιχεία ατόμου επικοινωνίας",
    subtitle2: "Στοιχεία συμμετοχής",
    subtitleSocials: "Λογαριασμοί Μέσων Κοινωνικής Δικτύωσης",
    fullname: "Όνομα και Επώνυμο",
    email: "Διεύθυνση ηλεκτρονικού ταχυδρομείου",
    email2: "Επαλήθευση Διεύθυνσης ηλεκτρονικού ταχυδρομείου",
    tel: "Τηλέφωνο Επικοινωνίας",
    age: "Είμαι άνω των 18 ετών",
    prevParticipation: "Έχετε ξανασυμμετάσχει στο φεστιβάλ Make Music Cyprus",
    hear: "Από που έχεις ακούσει/μάθει για το φεστιβάλ Make Music Cyprus? (μπορείς να διαλέξεις πάνω από μία απάντηση)",
    hearOptions: [
      { name: "Μέσα κοινωνικής δικτύωσης", value: "Social media" },
      { name: "Τηλεόραση", value: "TV spot" },
      { name: "Ραδιόφωνο", value: "Radio spot/announcement" },
      { name: "Ιστοσελίδα", value: "Website" },
      { name: "Αφίσα", value: "Poster" },
      {
        name: "Από προηγούμενο συμμεντέχοντα",
        value: "From a previous participant",
      },
      { name: "Από εθελοντή", value: "From a volunteer" },
      { name: "Από φίλο", value: "From a friend" },
      { name: "Από προηγούμενο φεστιβάλ", value: "From a previous festival" },
      { name: "Άλλο", value: "Other" },
    ],
    participationName:
      "Όνομα Συμμετοχής (όπως θα θέλατε να αναγράφεται στο πρόγραμμα - με Ελληνικούς και Λατινικούς χαρακτήρες για όπου ισχύει)",
    orgName: "Όνομα Οργανισμού, Ωδείου, Μουσικής Σχολής, Σχολείου (αν ισχύει)",
    city: "Πόλη Διαμονής",
    genre: "Μουσικό Είδος",
    applies: "Επιλέξτε τι ισχύει:",
    participants: "Ονόματα Συμμετεχόντων και μουσικά όργανα:",
    participantDescription:
      "Περιγραφή Συμμετοχής (μικρό βιογραφικό και λίγα λόγια για την εμφάνισή σας στο φεστιβάλ):",
    socialMediaAccounts:
      "Οι λογαριασμοί σας στα Μέσα Κοινωνικής Δικτύωσης (FB, Instagram, YouTube, Sound Cloud, Website, etc.)",
    facebookAccount: "Λογαριασμός/οί Facebook",
    instagramAccount: "Λογαριασμός/οί Instagram",
    youtubeAccount: "Λογαριασμός/οί YouTube",
    otherAccount:
      "Άλλος/οί Λογαριασμός/οί Μέσων Κοινωνικής Δικτύωσης ή Ιστοσελίδα",
    duration: "Διάρκεια Μουσικού Προγράμματος",
    availability:
      "Ώρες Διαθεσιμότητας (Μπορείτε να επιλέξετε περισσότερες από μία απαντήσεις)",
    availabilityFrom: "Ώρες Διαθεσιμότητας - Από",
    availabilityTo: "Ώρες Διαθεσιμότητας - Μέχρι",
    availabilityNote:
      "Παρακαλούμε όπως δηλώσετε διαθεσιμότητα τουλάχιστον 2 ωρών.",
    electricSupply: "Χρειάζομαι ηλεκτρική παροχή.",
    needEquipment:
      "Παρακαλώ όπως καταγράψετε τι εξοπλισμό χρειάζεστε (π.χ. ενισχυτή κιθάρας/μπάσου/άλλο, monitors, μικρόφωνα, όργανα, αναλόγια κλπ):",
    stands: "Χρειάζομαι μουσικά αναλόγια",
    support:
      "Είμαι πρόθυμος να φέρω τον δικό μου εξοπλισμό και με αυτόν τον τρόπο να υποστηρίξω τον μη κερδοσκοπικό χαρακτήρα του φεστιβάλ.",
    supportEquipment:
      "Παρακαλώ όπως καταγράψετε τι εξοπλισμό μπορείτε να φέρετε",
    online:
      "Στην περίπτωση που το φεστιβάλ θα διεξαχθεί διαδικτυακά θα ήθελες να συμμετάσχεις;",
    online2:
      "Αν απάντησες ναι στο πιο πάνω, πώς προτιμάς να είναι η συμμετοχή σου;",
    notes: "Υπάρχει κάτι άλλο που θα ήθελες να μοιραστείς μαζί μας?",
    photoConsent:
      "Συγκατατίθεμαι για φωτογράφιση, μαγνητοσκόπηση, ζωντανή μετάδοση καθώς και για τη δημοσίευση του υλικού αυτού. Για λόγους συμμόρφωσης με τον κανονισμό (ΕΕ) 2016/679 του Ευρωπαϊκού Κοινοβουλίου, για την προστασία των φυσικών προσώπων έναντι της επεξεργασίας των δεδομένων προσωπικού χαρακτήρα.",
    accept:
      "Αποδέχομαι να λαμβάνω μηνύματα στο ηλεκτρονικό ταχυδρομείο και στο τηλέφωνό μου που αφορούν σημαντικές ενημερώσεις ή ειδοποιήσεις σχετικά με την αίτησή μου.",
    notifications:
      "Επιθυμώ να λαμβάνω ειδοποιήσεις σχετικά με εκδηλώσεις και δραστηριότητες του Make Music Cyprus.",
    yes: "ΝΑΙ",
    no: "ΟΧΙ",
    liveStreaming: "Ζωντανή μετάδοση",
    premadeVideo: "Προπαρασκευασμένο Βίντεο",
    min: "λεπτά",
    solo: "Σόλο",
    duet: "Ντουέτο",
    band: "Συγκρότημα - Μουσικό Σύνολο - Ορχήστρα",
    choir: "Φωνητικό Σύνολο - Χορωδία",
    dance: "Χορευτικό Σύνολο",
    photoUpload:
      "Ανεβάστε 2 φωτογραφίες για σκοπούς προώθηση της συμμετοχής σας. (jpg)",
    submitInfo:
      "Αφού υποβάλετε τη φόρμα, θα επικοινωνήσουμε μαζί σας μέσω ηλεκτρονικού ταχυδρομείου. Αν δεν λάβετε email παρακαλώ επικοινωνήστε μαζί μας.",
  },
};

const organa = [
  // {
  //   en: "",
  //   gr: "",
  // },
  {
    en: "Voice",
    gr: "Φωνή",
  },
  {
    en: "Vocals",
    gr: "Φωνητικά",
  },
  // {
  // en: "",
  // gr: "",
  // },
  {
    en: "Classical Guitar",
    gr: "Κλασσική Κιθάρα",
  },
  {
    en: "Electro Classical Guitar",
    gr: "Κιθάρα Ηλεκτροκλασσική",
  },
  {
    en: "Acoustic Guitar",
    gr: "Κιθάρα Ακουστική",
  },
  {
    en: "Electro Acoustic Guitar",
    gr: "Κιθάρα Ηλεκτροακουστική",
  },
  {
    en: "Electric Guitar",
    gr: "Κιθάρα Ηλεκτρική",
  },
  {
    en: "Ukulele",
    gr: "Γιουκαλίλι",
  },
  // {
  //   en: "",
  //   gr: "",
  // },
  {
    en: "Acoustic Bass",
    gr: "Ακουστικό Μπάσο",
  },
  {
    en: "Electro Acoustic Bass",
    gr: "Ηλεκτρικοακουστικό Μπάσο",
  },
  {
    en: "Electric Bass",
    gr: "Ηλεκτρικό Μπάσο",
  },
  // {
  //   en: "",
  //   gr: "",
  // },
  {
    en: "Harp",
    gr: "Άρπα",
  },
  {
    en: "Kanonaki",
    gr: "Κανονάκι",
  },
  {
    en: "Oud",
    gr: "Λαούτο",
  },
  {
    en: "Lyre",
    gr: "Λύρα",
  },
  {
    en: "Mandolin",
    gr: "Μαντολίνο",
  },
  {
    en: "Baglamas",
    gr: "Μπαγλαμάς",
  },
  {
    en: "Bouzouki",
    gr: "Μπουζούκι",
  },
  {
    en: "Zither",
    gr: "Σαντούρι",
  },
  {
    en: "Tampouras",
    gr: "Ταμπουράς",
  },
  {
    en: "Tzouras",
    gr: "Τζουράς",
  },
  // {
  //   en: "",
  //   gr: "",
  // },
  {
    en: "Bongos",
    gr: "Bongos",
  },
  {
    en: "Cajon",
    gr: "Cajon",
  },
  {
    en: "Congas",
    gr: "Congas",
  },
  {
    en: "Djembes",
    gr: "Djembes",
  },
  {
    en: "Vibraphone",
    gr: "Βιμπράφωνο",
  },
  {
    en: "Metallophone",
    gr: "Μεταλόφωνο",
  },
  {
    en: "Davul",
    gr: "Νταούλι",
  },
  {
    en: "Χylophone",
    gr: "Ξυλόφωνο",
  },
  {
    en: "Traditional Percussion",
    gr: "Παραδοσιακά Κρουστά",
  },
  {
    en: "Hand Drum",
    gr: "Ταμπουρίνο",
  },
  {
    en: "Goblet drum / Darbukas",
    gr: "Τουμπελέκι",
  },
  {
    en: "Mammoth",
    gr: "Τύπμανα",
  },
  // {
  //   en: "",
  //   gr: "",
  // },
  {
    en: "Accordion",
    gr: "Ακκορντεόν",
  },
  {
    en: "Harmonium",
    gr: "Αρμόνιο",
  },
  {
    en: "Keyboard",
    gr: "Πιάνο",
  },
  {
    en: "Organ",
    gr: "Εκκλησιαστικό Όργανο",
  },
  // {
  //   en: "",
  //   gr: "",
  // },
  {
    en: "Violin",
    gr: "Βιολί",
  },
  {
    en: "Electric/Silent Violin",
    gr: "Βιολί Ηλεκτρικό",
  },
  {
    en: "Viola",
    gr: "Βιόλα",
  },
  {
    en: "Electric/Silent Viola",
    gr: "Βιόλα Ηλεκτρική",
  },
  {
    en: "Cello",
    gr: "Βιολοντσέλο",
  },
  {
    en: "Electric/Silent Cello",
    gr: "Βιολοντσέλο Ηλεκτρικό",
  },
  {
    en: "Contrabass",
    gr: "Κοντραμπάσο",
  },
  {
    en: "Electric/Silent Contrabass",
    gr: "Κοντραμπάσο Ηλεκτρικό",
  },
  // {
  //   en: "",
  //   gr: "",
  // },
  {
    en: "Altohorn",
    gr: "Αλτικόρνο",
  },
  {
    en: "Pan Pipe/Flute",
    gr: "Αυλός Πανός",
  },
  {
    en: "Euphonium",
    gr: "Ευφώνιο",
  },
  {
    en: "Zurna",
    gr: "Ζουρνάς",
  },
  {
    en: "Clarinet",
    gr: "Κλαρινέτο",
  },
  {
    en: "Horn",
    gr: "Κόρνα",
  },
  {
    en: "Cornet",
    gr: "Κορνέτα",
  },
  {
    en: "Μelodica",
    gr: "Μελόντικα",
  },
  {
    en: "Dijeridou",
    gr: "Ντιτζεριντού",
  },
  {
    en: "Oboe",
    gr: "Όμποε",
  },
  {
    en: "Saxophone",
    gr: "Σαξόφωνο",
  },
  {
    en: "Sousaphone",
    gr: "Σουζάφωνο",
  },
  {
    en: "Tuba",
    gr: "Τούμπα",
  },
  {
    en: "Trumpet",
    gr: "Τρομπέτα",
  },
  {
    en: "Trombone",
    gr: "Τρομπόνι",
  },
  {
    en: "Bassoon",
    gr: "Φαγγότο",
  },
  {
    en: "Flute",
    gr: "Φλάουτο",
  },
  {
    en: "Flugenhorns",
    gr: "Φλικόρνο",
  },
  {
    en: "Recorder",
    gr: "Φλογέρα",
  },
  {
    en: "Harmonica",
    gr: "Φυσαρμόνικα",
  },
  // {
  //   en: "",
  //   gr: "",
  // },
  {
    en: "Other (Please Specify)",
    gr: "Άλλο (Παρακαλώ διευκρινίστε)",
  },
];

const availabilityFrom = [
  "",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
];

const availabilityTo = [
  "",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
  "00:00",
];

const genres_en = [
  {
    label: "",
    value: "",
  },
  {
    label: "Acoustic",
    value: "Acoustic",
  },
  {
    label: "African",
    value: "African",
  },
  {
    label: "Ambient Music",
    value: "Ambient Music",
  },
  {
    label: "American",
    value: "American",
  },
  {
    label: "Ballads",
    value: "Ballads",
  },
  {
    label: "Bedroom pop",
    value: "Bedroom pop",
  },
  {
    label: "Blues",
    value: "Blues",
  },
  {
    label: "Byzantine",
    value: "Byzantine",
  },
  {
    label: "Classical",
    value: "Classical",
  },
  {
    label: "Classical/Pop Crossover",
    value: "Classical/Pop Crossover",
  },
  {
    label: "Country",
    value: "Country",
  },
  {
    label: "Dubstep",
    value: "Dubstep",
  },
  {
    label: "Electronic",
    value: "Electronic",
  },
  {
    label: "Éntekhno",
    value: "Éntekhno",
  },
  {
    label: "Ethnic",
    value: "Ethnic",
  },
  {
    label: "Ethnic Dance",
    value: "Ethnic Dance",
  },
  {
    label: "Experimental",
    value: "Experimental",
  },
  {
    label: "Funk",
    value: "Funk",
  },
  {
    label: "Gospel",
    value: "Gospel",
  },
  {
    label: "Greek Ballads",
    value: "Greek Ballads",
  },
  {
    label: "Greek Punk Rock",
    value: "Greek Punk Rock",
  },
  {
    label: "Hip Hop",
    value: "Hip Hop",
  },
  {
    label: "Improvised",
    value: "Improvised",
  },
  {
    label: "Indie",
    value: "Indie",
  },
  {
    label: "Jazz",
    value: "Jazz",
  },
  {
    label: "Latin",
    value: "Latin",
  },
  {
    label: "Latin American",
    value: "Latin American",
  },
  {
    label: "Latin Jazz",
    value: "Latin Jazz",
  },
  {
    label: "Laïkó",
    value: "Laïkó",
  },
  {
    label: "Light Laïkó",
    value: "Light Laïkó",
  },
  {
    label: "Metal",
    value: "Metal",
  },
  {
    label: "Heavy Metal",
    value: "Heavy Metal",
  },
  {
    label: "Epic Heavy Metal",
    value: "Epic Heavy Metal",
  },
  {
    label: "Death Metal",
    value: "Death Metal",
  },
  {
    label: "Musicals",
    value: "Musicals",
  },
  {
    label: "Opera",
    value: "Opera",
  },
  {
    label: "Pop",
    value: "Pop",
  },
  {
    label: "Pop rock",
    value: "Pop rock",
  },
  {
    label: "Rock",
    value: "Rock",
  },
  {
    label: "Altenrative Rock",
    value: "Altenrative Rock",
  },
  {
    label: "Classic Rock",
    value: "Classic Rock",
  },
  {
    label: "Stoner Rock",
    value: "Stoner Rock",
  },
  {
    label: "Progressive Rock",
    value: "Progressive Rock",
  },
  {
    label: "Psychedelic Rock",
    value: "Psychedelic Rock",
  },
  {
    label: "Rap",
    value: "Rap",
  },
  {
    label: "Rebetiko",
    value: "Rebetiko",
  },
  {
    label: "Reggae",
    value: "Reggae",
  },
  {
    label: "RnB",
    value: "RnB",
  },
  {
    label: "Rock N Roll",
    value: "Rock N Roll",
  },
  {
    label: "Ska",
    value: "Ska",
  },
  {
    label: "Soul",
    value: "Soul",
  },
  {
    label: "Soundtracks",
    value: "Soundtracks",
  },
  {
    label: "Techno",
    value: "Techno",
  },
  {
    label: "Traditional Cypriot",
    value: "Traditional Cypriot",
  },
  {
    label: "Traditional Greek",
    value: "Traditional Greek",
  },
  {
    label: "Folk/Traditional",
    value: "Folk/Traditional",
  },
  {
    label: "World",
    value: "World",
  },
  {
    label: "Other (Please Specify)",
    value: "Other",
  },
];

const genres_gr = [
  {
    label: "",
    value: "",
  },
  {
    label: "Bedroom pop",
    value: "Bedroom pop",
  },
  {
    label: "Blues",
    value: "Blues",
  },
  {
    label: "Classical/Pop Crossover",
    value: "Classical/Pop Crossover",
  },
  {
    label: "Country",
    value: "Country",
  },
  {
    label: "Dubstep",
    value: "Dubstep",
  },
  {
    label: "Ethnic",
    value: "Ethnic",
  },
  {
    label: "Ethnic Dance",
    value: "Ethnic Dance",
  },
  {
    label: "Funk",
    value: "Funk",
  },
  {
    label: "Gospel",
    value: "Gospel",
  },
  {
    label: "Hip Hop",
    value: "Hip Hop",
  },
  {
    label: "Indie",
    value: "Indie",
  },
  {
    label: "Jazz",
    value: "Jazz",
  },
  {
    label: "Latin",
    value: "Latin",
  },
  {
    label: "Latin American",
    value: "Latin American",
  },
  {
    label: "Latin Jazz",
    value: "Latin Jazz",
  },
  {
    label: "Metal",
    value: "Metal",
  },
  {
    label: "Heavy Metal",
    value: "Heavy Metal",
  },
  {
    label: "Epic Heavy Metal",
    value: "Epic Heavy Metal",
  },
  {
    label: "Death Metal",
    value: "Death Metal",
  },
  {
    label: "Musicals",
    value: "Musicals",
  },
  {
    label: "Pop",
    value: "Pop",
  },
  {
    label: "Pop rock",
    value: "Pop rock",
  },
  {
    label: "Rock",
    value: "Rock",
  },
  {
    label: "Altenrative Rock",
    value: "Altenrative Rock",
  },
  {
    label: "Classic Rock",
    value: "Classic Rock",
  },
  {
    label: "Stoner Rock",
    value: "Stoner Rock",
  },
  {
    label: "Progressive Rock",
    value: "Progressive Rock",
  },
  {
    label: "Psychedelic Rock",
    value: "Psychedelic Rock",
  },
  {
    label: "Rap",
    value: "Rap",
  },
  {
    label: "Reggae",
    value: "Reggae",
  },
  {
    label: "RnB",
    value: "RnB",
  },
  {
    label: "Rock N Roll",
    value: "Rock N Roll",
  },
  {
    label: "Ska",
    value: "Ska",
  },
  {
    label: "Soul",
    value: "Soul",
  },
  {
    label: "Soundtracks",
    value: "Soundtracks",
  },
  {
    label: "Techno",
    value: "Techno",
  },
  {
    label: "World",
    value: "World",
  },
  {
    label: "Ακουστική",
    value: "Acoustic",
  },
  {
    label: "Αμερικανική",
    value: "American",
  },
  {
    label: "Ατμοσφαιρική",
    value: "Ambient Music",
  },
  {
    label: "Αυτοσχεδιαστική",
    value: "Improvised",
  },
  {
    label: "Αφρικανική",
    value: "African",
  },
  {
    label: "Βυζαντινή",
    value: "Byzantine",
  },
  {
    label: "Ελληνικές Μπαλάντες",
    value: "Greek Ballads",
  },
  {
    label: "Ελληνικό Punk Rock",
    value: "Greek Punk Rock",
  },
  {
    label: "Έντεχνο",
    value: "Éntekhno",
  },
  {
    label: "Ηλεκτρονική",
    value: "Electronic",
  },
  {
    label: "Κλασική",
    value: "Classical",
  },
  {
    label: "Λαϊκό",
    value: "Laïkó",
  },
  {
    label: "Ελαφρολαϊκό",
    value: "Light Laïkó",
  },
  {
    label: "Μπαλάντες",
    value: "Ballads",
  },
  {
    label: "Όπερα",
    value: "Opera",
  },
  {
    label: "Παραδοσιακή Κυπριακή",
    value: "Traditional Cypriot",
  },
  {
    label: "Παραδοσιακή Ελληνική",
    value: "Traditional Greek",
  },
  {
    label: "Δημοτική/Παραδοσιακή",
    value: "Folk/Traditional",
  },
  {
    label: "Πειραματική",
    value: "Experimental",
  },
  {
    label: "Ρεμπέτικο",
    value: "Rebetiko",
  },
  {
    label: "Άλλο (Παρακαλώ διευκρινίστε)",
    value: "Other",
  },
];

const emailMessage = {
  en: "Dear applicant,<br/><br/>We highly appreciate your interest in participating in the Make Music Cyprus Festival 2025. We have successfully received your application and will contact you by the end of March.<br/><br/>If you have any questions in the meantime, please don't hesitate to contact us either by replying to this email or by calling us at <a href='tel:0035770000216'>+357 700 00 216</a>.<br/><br/>Kind Regards,<br/>Make Music Cyprus Festival Team<br/><br/>Below, you can find the information you filled in your application.",
  gr: "Αγαπητή/έ/οί,<br/><br/>Εκτιμούμε ιδιαίτερα το ενδιαφέρον σας να συμμετάσχετε στο Φεστιβάλ Make Music Cyprus 2025. Έχουμε παραλάβει την αίτηση σας επιτυχώς και θα επικοινωνήσουμε μαζί σας το αργότερο μέχρι τέλος Μαρτίου.<br/><br/>Εάν στο ενδιάμεσο έχετε οποιαδήποτε απορία, μη διστάσετε να επικοινωνήσετε μαζί μας είτε απαντώντας σε αυτό το email είτε στο τηλέφωνο <a href='tel:0035770000216'>+357 700 00 216</a>.<br/><br/>Με εκτίμηση,<br/>Ομάδα Φεστιβάλ Make Music Cyprus<br/><br/>Πιο κάτω, μπορείτε να βρείτε τα στοιχεία που συμπληρώσατε στην αίτησης σας.",
};

const yeroron = {
  en: {
    ΟΧΙ: "NO",
    ΝΑΙ: "YES",
  },
  gr: {
    ΟΧΙ: "ΟΧΙ",
    ΝΑΙ: "ΝΑΙ",
  },
};

const asterisk = <span style={{ color: colors.orange1 }}>*</span>;

export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  return `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:00`;
};

export function emailValidation(email) {
  const emailRegexp = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return emailRegexp.test(email);
}

export function SignUpPerform() {
  useTitle("Participation Form - Make Music Cyprus");
  const size = useWindowSize();

  const history = useHistory();

  const recaptchaRef = React.useRef();
  const [recaptcha, setRecaptcha] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const [labels, setLabels] = React.useState(texts.en);
  const [lang, setLang] = React.useState("en");

  const [fullname, setFullname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [email2, setEmail2] = React.useState("");
  const [telephone, setTelephone] = React.useState("");
  const [age, setAge] = React.useState(null);

  const [prevParticipation, setPrevParticipation] = React.useState(null);
  const [hear, setHear] = React.useState([]);
  const [hearOther, setHearOther] = React.useState("");
  const [participationName, setParticipationName] = React.useState("");
  const [participations, setParticipations] = React.useState([
    { name: "", organo: "", other: "" },
  ]);
  const [orgName, setOrgName] = React.useState("");
  const [city, setCity] = React.useState("");
  const [genre, setGenre] = React.useState("");
  const [otherGenre, setOtherGenre] = React.useState("");
  const [applies, setApplies] = React.useState(null);
  const [participantDescription, setParticipantDescription] =
    React.useState("");
  const [facebookAccount, setFacebookAccount] = React.useState("");
  const [instagramAccount, setInstagramAccount] = React.useState("");
  const [youtubeAccount, setYoutubeAccount] = React.useState("");
  const [otherAccount, setOtherAccount] = React.useState("");
  const [duration, setDuration] = React.useState(null);
  const [availabilityFrom, setAvailabilityFrom] = React.useState("");
  const [availabilityTo, setAvailabilityTo] = React.useState("");
  const [electricSupply, setElectricSupply] = React.useState(null);
  const [needEquipment, setNeedEquipment] = React.useState("");
  const [support, setSupport] = React.useState("");
  const [supportEquipment, setSupportEquipment] = React.useState("");
  const [notes, setNotes] = React.useState("");
  const [photoConsent, setPhotoConsent] = React.useState(null);
  const [accept, setAccept] = React.useState(null);
  const [notifications, setNotifications] = React.useState("ΟΧΙ");
  const [photos, setPhotos] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  const [showErrorBorder, setShowErrorBorder] = React.useState(false);

  const submitDisabled =
    fullname.length === 0 ||
    email.length === 0 ||
    !emailValidation(email) ||
    email2 !== email ||
    telephone.length === 0 ||
    !age ||
    !prevParticipation ||
    participationName.length === 0 ||
    city.length === 0 ||
    genre.length === 0 ||
    (genre === "Other" && otherGenre.length === 0) ||
    !applies ||
    participations.length === 0 ||
    participations.some(
      (p) =>
        p.name === "" ||
        p.organo === "" ||
        ((p.organo.includes("Other (Please Specify)") ||
          p.organo.includes("Άλλο (Παρακαλώ διευκρινίστε)")) &&
          p.other === "")
    ) ||
    participantDescription.length === 0 ||
    photos.length === 0 ||
    !duration ||
    availabilityFrom.length === 0 ||
    availabilityTo.length === 0 ||
    !electricSupply ||
    (support && support === "ΝΑΙ" ? supportEquipment.length === 0 : false) ||
    !photoConsent ||
    !accept ||
    !recaptcha;

  function clearAll() {
    setFullname("");
    setEmail("");
    setEmail2("");
    setTelephone("");
    setAge(null);
    setPrevParticipation(null);
    setHear([]);
    setHearOther("");
    setParticipationName("");
    setOrgName("");
    setCity("");
    setGenre("");
    setApplies("");
    setParticipations([{ name: "", organo: "", other: "" }]);
    setParticipantDescription("");
    setParticipantDescription("");
    setFacebookAccount("");
    setInstagramAccount("");
    setYoutubeAccount("");
    setOtherAccount("");
    setDuration("");
    setAvailabilityFrom("");
    setAvailabilityTo("");
    setElectricSupply("");
    setNeedEquipment("");
    setSupport("");
    setSupportEquipment("");
    setNotes("");
    setPhotoConsent(null);
    setAccept("");
    setNotifications("");
    setShowErrorBorder(false);
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (submitDisabled) {
      setShowErrorBorder(true);
      alert("Some mandatory fields have not been filled");
      return;
    }

    setLoading(true);

    let formattedAppliesValue = applies;
    switch (applies) {
      case "Σόλο":
        formattedAppliesValue = texts[lang].solo;
        break;
      case "Ντουέτο":
        formattedAppliesValue = texts[lang].duet;
        break;
      case "Συγκρότημα - Μουσικό Σύνολο - Ορχήστρα":
        formattedAppliesValue = texts[lang].band;
        break;
      case "Φωνητικό Σύνολο - Χορωδία":
        formattedAppliesValue = texts[lang].choir;
        break;
      case "Χορευτικό Σύνολο":
        formattedAppliesValue = texts[lang].dance;
        break;
      default:
        break;
    }

    axios
      .post(
        FORM_ACTION,
        {
          lang,
          email,
          data: [
            formatTimestamp(new Date()),
            "",
            "",
            "",
            "",
            "",
            participationName,
            "",
            "",
            "",
            genre === "Other" ? otherGenre : genre,
            "",
            "",
            applies,
            "",
            participations
              .map(
                (p) =>
                  `${p.name} -- ${p.organo
                    .replace("Other (Please Specify)", p.other)
                    .replace("Άλλο (Παρακαλώ διευκρινίστε)", p.other)}`
              )
              .join(" | "),
            "",
            "",
            participations.length,
            participantDescription,
            age,
            prevParticipation,
            "",
            orgName,
            "",
            electricSupply,
            needEquipment,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            support,
            supportEquipment,
            "",
            "",
            "",
            notes,
            "",
            "",
            "",
            `${availabilityFrom}:00`,
            `${availabilityTo}:00`,
            duration,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            fullname,
            email,
            telephone,
            "",
            city,
            "",
            facebookAccount,
            instagramAccount,
            youtubeAccount,
            "",
            "",
            "",
            otherAccount,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "Φόρμα",
            photoConsent,
            notifications,
            hear.join(", ").replace("Other", hearOther),
            "",
            "",
            "",
          ],
          emailMessage: `${emailMessage[lang]}<br/><br/><b>${
            labels.fullname
          }</b>: ${fullname}<br/><b>${labels.email}</b>: ${email}<br/><b>${
            labels.tel
          }</b>: ${telephone}<br/><b>${labels.age}</b>: ${
            yeroron[lang][age]
          }<br/><b>${labels.prevParticipation}</b>: ${
            yeroron[lang][prevParticipation]
          }<br/><b>${labels.hear}</b>: ${hear
            .map((h) => texts[lang].hearOptions.find((v) => v.value === h).name)
            .join(", ")
            .replace("Other", hearOther)}<br/><b>${
            labels.participationName
          }</b>: ${participationName}<br/><b>${
            labels.orgName
          }</b>: ${orgName}<br/><b>${labels.city}</b>: ${city}<br/><b>${
            labels.genre
          }</b>: ${
            genre === "Other"
              ? otherGenre
              : (lang === "en" ? genres_en : genres_gr).find(
                  (g) => g.value === genre
                ).label
          }<br/><b>${labels.applies}</b> ${formattedAppliesValue}<br/><b>${
            labels.participants
          }</b> ${participations
            .map(
              (p) =>
                `${p.name} -- ${p.organo
                  .replace("Other (Please Specify)", p.other)
                  .replace("Άλλο (Παρακαλώ διευκρινίστε)", p.other)}`
            )
            .join(" | ")}<br/><b>${
            labels.participantDescription
          }</b> ${participantDescription}<br/><b>${
            labels.facebookAccount
          }</b>: ${facebookAccount}<br/><b>${
            labels.instagramAccount
          }</b>: ${instagramAccount}<br/><b>${
            labels.youtubeAccount
          }</b>: ${youtubeAccount}<br/><b>${
            labels.otherAccount
          }</b>: ${otherAccount}<br/><b>${
            labels.duration
          }</b>: ${duration}<br/><b>${
            labels.availabilityFrom
          }</b>: ${availabilityFrom}<br/><b>${
            labels.availabilityTo
          }</b>: ${availabilityTo}<br/><b>${labels.electricSupply}</b>: ${
            yeroron[lang][electricSupply]
          }<br/><b>${labels.needEquipment}</b> ${needEquipment}<br/><b>${
            labels.support
          }</b>: ${yeroron[lang][support]}<br/><b>${
            labels.supportEquipment
          }</b> ${supportEquipment}<br/><b>${
            labels.notes
          }</b> ${notes}<br/><b>${labels.photoConsent}</b> ${
            yeroron[lang][photoConsent]
          }<br/><b>${labels.accept}</b> ${yeroron[lang][accept]}<br/><b>${
            labels.notifications
          }</b> ${yeroron[lang][notifications]}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          uploadPhotos();
          clearAll();
          setRecaptcha(false);
          recaptchaRef.current.reset();
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  function onFileChange(e) {
    if (e.target.files.length > 1) {
      // if (e.target.files[0].size > 150000 || e.target.files[1].size > 150000) {
      //   alert("Please select image files with size less than 1.5MB");
      //   return;
      // }
      setPhotos([e.target.files[0], e.target.files[1]]);
    } else if (e.target.files && e.target.files[0]) {
      // if (e.target.files[0].size > 150000) {
      //   e.target.value = null;
      //   alert("Please select image files with size less than 1.5MB");
      //   return;
      // }
      setPhotos(e.target.files);
    } else {
      setPhotos([]);
    }
  }

  async function uploadPhotos() {
    const compress = new compressJS();
    const resizedFiles = await compress.compress(Array.from(photos), {
      size: 1.5,
      quality: 1,
      resize: false,
    });
    Array.from(photos).forEach((photo, index) => {
      // const reader = new FileReader();
      // reader.readAsDataURL(resizedFiles[index]);
      // reader.onload = function (e) {
      //   const rawLog = reader.result.split(",")[1];
      const dataSend = {
        dataReq: {
          data: resizedFiles[index].data,
          name: `${participationName} - ${index}`,
          type: photo.type,
        },
        fname: "uploadFilesToGoogleDrive",
      };
      axios
        .post(UPLOAD_PHOTO_ACTION, dataSend)
        .then(() => {
          setPhotos([]);
          setSuccess(true);
          setLoading(false);
          document.getElementById("customFile").value = "";
        })
        .catch(() => {
          setPhotos([]);
          setSuccess(true);
          setLoading(false);
          document.getElementById("customFile").value = "";
        });
      // };
    });
  }

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="Make Music Cyprus - THERE IS ALWAYS SPACE FOR AN EXTRA PAIR OF HANDS AND A SUPPORTIVE MIND"
        />
      </Helmet>
      <Module height="fit-content" background={colors.orange1}>
        {(loading || success) && (
          <Loading>
            {loading && <Loader />}
            {success && (
              <Message color={colors.orange1}>
                Hooray! Your submission was successful!
                <br />
                <br />
                <AddBtn onClick={() => history.push("/festival")}>OK</AddBtn>
              </Message>
            )}
          </Loading>
        )}
        <FormContainer>
          <Form onSubmit={handleSubmit}>
            <div
              style={{
                top: 0,
                width: "calc(100% - 10vw)",
                display: "flex",
                flexDirection: "row",
                position: "absolute",
                justifyContent: "center",
              }}
            >
              <LanguageSelector>
                <div
                  onClick={() => {
                    setLabels(texts.en);
                    setLang("en");
                  }}
                >
                  ENG
                </div>
                <span>|</span>
                <div
                  onClick={() => {
                    setLabels(texts.gr);
                    setLang("gr");
                  }}
                >
                  ΕΛ
                </div>
              </LanguageSelector>
            </div>
            <Title>{labels.title}</Title>
            <br />
            <br />
            <div>
              {lang === "en" ? (
                <div style={{ fontSize: 16 }}>
                  The Make Music Cyprus Festival has been well established in
                  Cyprus and is probably the largest street music festival in
                  the country, as more than 450 artists performed in 2024 alone.
                  <br />
                  <br />
                  This festival is based on volunteerism and community
                  contribution. The festival's organizing team is made up of
                  volunteers, participants take part pro bono, and access to the
                  public is free of charge. It is a festival by the community
                  for the community, and you can contribute to it too!
                  <br />
                  <br />
                  Join the 7th Make Music Cyprus Festival on{" "}
                  <b>Saturday, June 21st, 2025!</b>
                  <br />
                  <br />
                  Unlike a traditional music festival, Make Music Cyprus
                  presents musicians of all levels and skills, regardless of
                  age, gender, nationality and musical genre. We invite:
                  <br />
                  <ul>
                    <li>Professional musicians</li>
                    <li>Amateur musicians</li>
                    <li>Students</li>
                    <li>Choirs</li>
                    <li>Orchestras</li>
                    <li>Bands and ensembles</li>
                    <li>Conservatories and music schools</li>
                    <li>Dance Ensembles</li>
                  </ul>
                  Deadline for applications is 22 February 2025.
                  <br />
                  <br />
                  For more information contact us at:{" "}
                  <a href="mailto:participate@makemusiccyprus.org">
                    participate@makemusiccyprus.org
                  </a>{" "}
                  or call us at <a href="tel:0035770000216">+357 700 00 216</a>.
                </div>
              ) : (
                <div style={{ fontSize: 16 }}>
                  Το Φεστιβάλ Make Music Cyprus έχει καθιερωθεί στην Κύπρο και
                  είναι αυτή τη στιγμή ίσως το μεγαλύτερο street music festival
                  της χώρας, αφού μόνο το 2024 έλαβαν μέρος περισσότεροι από 450
                  καλλιτέχνες
                  <br />
                  <br />
                  Ο θεσμός αυτός στηρίζεται στον εθελοντισμό και στην προσφορά
                  της κοινότητας. Η ομάδα διοργάνωσης του φεστιβάλ απαρτίζεται
                  από εθελοντές, οι συμμετέχοντες λαμβάνουν μέρος αφιλοκερδώς,
                  και η πρόσβαση στο κοινό είναι δωρεάν. Είναι ένα φεστιβάλ από
                  τον κόσμο για τον κόσμο, στο οποίο μπορείς να συμβάλεις και
                  εσύ!
                  <br />
                  <br />
                  Λάβε μέρος και εσύ στο 7ο Φεστιβάλ Make Music Cyprus, το{" "}
                  <b>Σάββατο 21 Ιουνίου 2025!</b>
                  <br />
                  <br />
                  Σε αντίθεση με ένα παραδοσιακό μουσικό φεστιβάλ, το Make Music
                  Cyprus παρουσιάζει μουσικούς όλων των επιπέδων και δεξιοτήτων,
                  ανεξαρτήτως ηλικίας, φύλου, εθνικότητας και μουσικού είδους.
                  Προσκαλούμε:
                  <br />
                  <ul>
                    <li>Επαγγελματίες μουσικούς</li>
                    <li>Ερασιτέχνες μουσικούς</li>
                    <li>Σπουδαστές</li>
                    <li>Χορωδίες</li>
                    <li>Ορχήστρες</li>
                    <li>Μπάντες και μουσικά σύνολα</li>
                    <li>Ωδεία και μουσικές σχολές</li>
                    <li>Χορευτικά Σύνολα</li>
                  </ul>
                  <b>Τελευταία μέρα υποβολής αιτήσεων η 22 Φεβρουαρίου 2025.</b>
                  <br />
                  <br />
                  Για περισσότερες πληροφορίες επικοινωνήστε μαζί μας στο:{" "}
                  <a href="mailto:participate@makemusiccyprus.org">
                    participate@makemusiccyprus.org
                  </a>{" "}
                  ή στο τηλέφωνο <a href="tel:0035770000216">+357 700 00 216</a>
                  .
                </div>
              )}
              <br />
              <br />
              <br />
              <Subtitle>{labels.subtitle1}</Subtitle>
              <br />
              <div style={{ width: "100%", textAlign: "right", fontSize: 16 }}>
                {asterisk} Required
              </div>
              <br />
              <Row
                marginBottom="10px"
                showErrorBorder={showErrorBorder && fullname.length === 0}
              >
                <Label>
                  {labels.fullname} {asterisk}
                </Label>
                <InputField
                  type="text"
                  value={fullname}
                  onChange={(e) => setFullname(e.target.value)}
                />
              </Row>
              <Row
                marginBottom="10px"
                showErrorBorder={
                  showErrorBorder &&
                  (email.length === 0 ||
                    !emailValidation(email) ||
                    email !== email2)
                }
              >
                <Label>
                  {labels.email} {asterisk}
                </Label>
                <InputField
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Row>
              <Row
                marginBottom="10px"
                showErrorBorder={
                  showErrorBorder &&
                  (email2.length === 0 ||
                    !emailValidation(email2) ||
                    email !== email2)
                }
              >
                <Label>
                  {labels.email2} {asterisk}
                </Label>
                <InputField
                  type="text"
                  value={email2}
                  onChange={(e) => setEmail2(e.target.value)}
                />
              </Row>
              <Row
                marginBottom="10px"
                showErrorBorder={showErrorBorder && telephone.length === 0}
              >
                <Label>
                  {labels.tel} {asterisk}
                </Label>
                <InputField
                  type="number"
                  value={telephone}
                  onChange={(e) => setTelephone(e.target.value)}
                />
              </Row>
              <Row
                marginBottom="20px"
                showErrorBorder={showErrorBorder && !age}
              >
                <Label marginBottom="10px">
                  {labels.age} {asterisk}
                </Label>
                <InputRadioField>
                  <input
                    type="radio"
                    id="ΝΑΙ 4"
                    name="age"
                    value="ΝΑΙ"
                    checked={age === "ΝΑΙ"}
                    onChange={(e) => setAge(e.target.value)}
                  />
                  <label htmlFor="ΝΑΙ 4">{labels.yes}</label>
                </InputRadioField>
                <InputRadioField>
                  <input
                    type="radio"
                    id="ΟΧΙ 4"
                    name="age"
                    value="ΟΧΙ"
                    checked={age === "ΟΧΙ"}
                    onChange={(e) => setAge(e.target.value)}
                  />
                  <label htmlFor="ΟΧΙ 4">{labels.no}</label>
                </InputRadioField>
              </Row>
              <br />
              <br />
              <Subtitle>{labels.subtitle2}</Subtitle>
              <br />
              <br />
              <Row
                marginBottom="10px"
                showErrorBorder={
                  showErrorBorder && participationName.length === 0
                }
              >
                <Label>
                  {labels.participationName} {asterisk}
                </Label>
                <InputField
                  type="text"
                  value={participationName}
                  onChange={(e) => setParticipationName(e.target.value)}
                />
              </Row>
              <Row marginBottom="10px">
                <Label>{labels.orgName}</Label>
                <InputField
                  type="text"
                  value={orgName}
                  onChange={(e) => setOrgName(e.target.value)}
                />
              </Row>
              <Row
                marginBottom="20px"
                showErrorBorder={showErrorBorder && !applies}
              >
                <Label marginBottom="10px">
                  {labels.applies} {asterisk}
                </Label>
                <InputRadioField>
                  <input
                    type="radio"
                    id="Σόλο"
                    name="applies"
                    value="Σόλο"
                    checked={applies === "Σόλο"}
                    onChange={(e) => setApplies(e.target.value)}
                  />
                  <label htmlFor="Σόλο">{labels.solo}</label>
                </InputRadioField>
                <InputRadioField>
                  <input
                    type="radio"
                    id="Ντουέτο"
                    name="applies"
                    value="Ντουέτο"
                    checked={applies === "Ντουέτο"}
                    onChange={(e) => setApplies(e.target.value)}
                  />
                  <label htmlFor="Ντουέτο">{labels.duet}</label>
                </InputRadioField>
                <InputRadioField>
                  <input
                    type="radio"
                    id="Συγκρότημα - Μουσικό Σύνολο - Ορχήστρα"
                    name="applies"
                    value="Συγκρότημα - Μουσικό Σύνολο - Ορχήστρα"
                    checked={
                      applies === "Συγκρότημα - Μουσικό Σύνολο - Ορχήστρα"
                    }
                    onChange={(e) => setApplies(e.target.value)}
                  />
                  <label htmlFor="Συγκρότημα - Μουσικό Σύνολο - Ορχήστρα">
                    {labels.band}
                  </label>
                </InputRadioField>
                <InputRadioField>
                  <input
                    type="radio"
                    id="Φωνητικό Σύνολο - Χορωδία"
                    name="applies"
                    value="Φωνητικό Σύνολο - Χορωδία"
                    checked={applies === "Φωνητικό Σύνολο - Χορωδία"}
                    onChange={(e) => setApplies(e.target.value)}
                  />
                  <label htmlFor="Φωνητικό Σύνολο - Χορωδία">
                    {labels.choir}
                  </label>
                </InputRadioField>
                <InputRadioField>
                  <input
                    type="radio"
                    id="Χορευτικό Σύνολο"
                    name="applies"
                    value="Χορευτικό Σύνολο"
                    checked={applies === "Χορευτικό Σύνολο"}
                    onChange={(e) => setApplies(e.target.value)}
                  />
                  <label htmlFor="Χορευτικό Σύνολο">{labels.dance}</label>
                </InputRadioField>
              </Row>
              <Grid>
                <Row
                  marginBottom="10px"
                  showErrorBorder={
                    showErrorBorder &&
                    (genre.length === 0 ||
                      (genre === "Other" && otherGenre.length === 0))
                  }
                >
                  <Label>
                    {labels.genre} {asterisk}
                  </Label>
                  <SelectField
                    value={genre}
                    style={{ marginBottom: 0 }}
                    onChange={(e) => setGenre(e.target.value)}
                  >
                    {(lang === "en" ? genres_en : genres_gr).map((g, i) => {
                      if (g.label === "") {
                        return (
                          <option key={`placeholder-${i}`} value={""} disabled>
                            {lang === "en"
                              ? "Please select an option"
                              : "Παρακαλώ επιλέξτε μία επιλογή"}
                          </option>
                        );
                      }
                      return (
                        <option key={g.value} value={g.value}>
                          {g.label}
                        </option>
                      );
                    })}
                  </SelectField>
                  {genre === "Other" && (
                    <InputField
                      type="text"
                      value={otherGenre}
                      onChange={(e) => setOtherGenre(e.target.value)}
                    />
                  )}
                </Row>
                <Row
                  marginBottom="10px"
                  showErrorBorder={showErrorBorder && city.length === 0}
                >
                  <Label>
                    {labels.city} {asterisk}
                  </Label>
                  <InputField
                    type="text"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </Row>
              </Grid>
              <Row
                marginBottom="10px"
                showErrorBorder={
                  showErrorBorder &&
                  (participations.length === 0 ||
                    participations.some(
                      (p) =>
                        p.name === "" ||
                        p.organo === "" ||
                        ((p.organo === "Other (Please Specify)" ||
                          p.organo === "Άλλο (Παρακαλώ διευκρινίστε)") &&
                          p.other === "")
                    ))
                }
              >
                <Label>
                  {labels.participants} {asterisk}
                </Label>
              </Row>
              <ParticipantsField
                instances={participations}
                setInstances={setParticipations}
                lang={lang}
              />
              <Row
                marginBottom="10px"
                showErrorBorder={
                  showErrorBorder && participantDescription.length === 0
                }
              >
                <Label>
                  {labels.participantDescription} {asterisk}
                </Label>
                <TextAreaField
                  value={participantDescription}
                  onChange={(e) => setParticipantDescription(e.target.value)}
                />
              </Row>
              <Row
                marginBottom="40px"
                showErrorBorder={showErrorBorder && photos.length === 0}
              >
                <Label htmlFor="customFile">
                  {labels.photoUpload} {asterisk}
                </Label>
                <br />
                <input
                  multiple
                  type="file"
                  id="customFile"
                  name="customFile"
                  accept=".jpg,.jpeg"
                  onChange={onFileChange}
                />
              </Row>
              <Row
                marginBottom="10px"
                showErrorBorder={showErrorBorder && !prevParticipation}
              >
                <Label marginBottom="10px">
                  {labels.prevParticipation} {asterisk}
                </Label>
                <InputRadioField>
                  <input
                    type="radio"
                    id="ΝΑΙ 5"
                    name="prevParticipation"
                    value="ΝΑΙ"
                    checked={prevParticipation === "ΝΑΙ"}
                    onChange={(e) => setPrevParticipation(e.target.value)}
                  />
                  <label htmlFor="ΝΑΙ 5">{labels.yes}</label>
                </InputRadioField>
                <InputRadioField>
                  <input
                    type="radio"
                    id="ΟΧΙ 5"
                    name="prevParticipation"
                    value="ΟΧΙ"
                    checked={prevParticipation === "ΟΧΙ"}
                    onChange={(e) => setPrevParticipation(e.target.value)}
                  />
                  <label htmlFor="ΟΧΙ 5">{labels.no}</label>
                </InputRadioField>
              </Row>
              <Row marginBottom="20px">
                <Label marginBottom="10px">{labels.hear}</Label>
                {labels.hearOptions.map((item) => (
                  <InputRadioField key={item.name}>
                    <input
                      type="checkbox"
                      id={item.value}
                      name="hear"
                      value={item.value}
                      checked={hear.indexOf(item.value) > -1}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setHear([...hear, e.target.value]);
                        } else {
                          setHear(
                            hear.filter((value) => value !== e.target.value)
                          );
                        }
                      }}
                    />
                    <label htmlFor={item.value}>{item.name}</label>
                  </InputRadioField>
                ))}
                {hear.indexOf("Other") > -1 && (
                  <InputField
                    type="text"
                    value={hearOther}
                    onChange={(e) => setHearOther(e.target.value)}
                  />
                )}
              </Row>
              <Row
                marginBottom="20px"
                showErrorBorder={showErrorBorder && !duration}
              >
                <Label marginBottom="10px">
                  {labels.duration} {asterisk}
                </Label>
                <InputRadioField>
                  <input
                    type="radio"
                    id="15"
                    name="duration"
                    value="15"
                    checked={duration === "15"}
                    onChange={(e) => setDuration(e.target.value)}
                  />
                  <label htmlFor="15">15 {labels.min}</label>
                </InputRadioField>
                <InputRadioField>
                  <input
                    type="radio"
                    id="20"
                    name="duration"
                    value="20"
                    checked={duration === "20"}
                    onChange={(e) => setDuration(e.target.value)}
                  />
                  <label htmlFor="20">20 {labels.min}</label>
                </InputRadioField>
                <InputRadioField>
                  <input
                    type="radio"
                    id="30"
                    name="duration"
                    value="30"
                    checked={duration === "30"}
                    onChange={(e) => setDuration(e.target.value)}
                  />
                  <label htmlFor="30">30 {labels.min}</label>
                </InputRadioField>
                <InputRadioField>
                  <input
                    type="radio"
                    id="45"
                    name="duration"
                    value="45"
                    checked={duration === "45"}
                    onChange={(e) => setDuration(e.target.value)}
                  />
                  <label htmlFor="45">45 {labels.min}</label>
                </InputRadioField>
              </Row>
              <AvailabilityHoursFields
                lang={lang}
                showErrorBorder={showErrorBorder}
                labels={labels}
                availabilityFrom={availabilityFrom}
                availabilityTo={availabilityTo}
                setAvailabilityFrom={setAvailabilityFrom}
                setAvailabilityTo={setAvailabilityTo}
              />
              <div style={{ margin: "-20px 0 20px 0", fontSize: "16px" }}>
                {labels.availabilityNote}
              </div>
              <Row
                marginBottom="20px"
                showErrorBorder={showErrorBorder && !electricSupply}
              >
                <Label marginBottom="10px">
                  {labels.electricSupply} {asterisk}
                </Label>
                <InputRadioField>
                  <input
                    type="radio"
                    id="ΝΑΙ 1"
                    name="electricSupply"
                    value="ΝΑΙ"
                    checked={electricSupply === "ΝΑΙ"}
                    onChange={(e) => setElectricSupply(e.target.value)}
                  />
                  <label htmlFor="ΝΑΙ 1">{labels.yes}</label>
                </InputRadioField>
                <InputRadioField>
                  <input
                    type="radio"
                    id="ΟΧΙ 1"
                    name="electricSupply"
                    value="ΟΧΙ"
                    checked={electricSupply === "ΟΧΙ"}
                    onChange={(e) => setElectricSupply(e.target.value)}
                  />
                  <label htmlFor="ΟΧΙ 1">{labels.no}</label>
                </InputRadioField>
              </Row>
              <Row marginBottom="10px">
                <Label>{labels.needEquipment}</Label>
                <TextAreaField
                  value={needEquipment}
                  onChange={(e) => setNeedEquipment(e.target.value)}
                />
              </Row>
              <Row marginBottom="20px">
                <Label marginBottom="10px">
                  {labels.support} {asterisk}
                </Label>
                <InputRadioField>
                  <input
                    type="radio"
                    id="ΝΑΙ 3"
                    name="support"
                    value="ΝΑΙ"
                    checked={support === "ΝΑΙ"}
                    onChange={(e) => setSupport(e.target.value)}
                  />
                  <label htmlFor="ΝΑΙ 3">{labels.yes}</label>
                </InputRadioField>
                <InputRadioField>
                  <input
                    type="radio"
                    id="ΟΧΙ 3"
                    name="support"
                    value="ΟΧΙ"
                    checked={support === "ΟΧΙ"}
                    onChange={(e) => setSupport(e.target.value)}
                  />
                  <label htmlFor="ΟΧΙ 3">{labels.no}</label>
                </InputRadioField>
              </Row>
              <Row marginBottom="10px">
                <Label>
                  {labels.supportEquipment} {support === "ΝΑΙ" && asterisk}
                </Label>
                <TextAreaField
                  value={supportEquipment}
                  onChange={(e) => setSupportEquipment(e.target.value)}
                />
              </Row>
              <Row marginBottom="10px">
                <Label>{labels.notes}</Label>
                <TextAreaField
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </Row>
              <br />
              <br />
              <Subtitle>{labels.subtitleSocials}</Subtitle>
              <br />
              <br />
              <Row marginBottom="20px">
                <Label>{labels.facebookAccount}</Label>
                <TextAreaField
                  value={facebookAccount}
                  onChange={(e) => setFacebookAccount(e.target.value)}
                />
              </Row>
              <Row marginBottom="20px">
                <Label>{labels.instagramAccount}</Label>
                <TextAreaField
                  value={instagramAccount}
                  onChange={(e) => setInstagramAccount(e.target.value)}
                />
              </Row>
              <Row marginBottom="20px">
                <Label>{labels.youtubeAccount}</Label>
                <TextAreaField
                  value={youtubeAccount}
                  onChange={(e) => setYoutubeAccount(e.target.value)}
                />
              </Row>
              <Row marginBottom="20px">
                <Label>{labels.otherAccount}</Label>
                <TextAreaField
                  value={otherAccount}
                  onChange={(e) => setOtherAccount(e.target.value)}
                />
              </Row>
              <Row
                marginBottom="30px"
                showErrorBorder={showErrorBorder && !photoConsent}
              >
                <Label marginBottom="10px">
                  {labels.photoConsent} {asterisk}
                </Label>
                <InputRadioField>
                  <input
                    type="radio"
                    id="ΝΑΙ 6"
                    name="photoConsent"
                    value="ΝΑΙ"
                    checked={photoConsent === "ΝΑΙ"}
                    onChange={(e) => setPhotoConsent(e.target.value)}
                  />
                  <label htmlFor="ΝΑΙ 6">{labels.yes}</label>
                </InputRadioField>
                <InputRadioField>
                  <input
                    type="radio"
                    id="ΟΧΙ 6"
                    name="photoConsent"
                    value="ΟΧΙ"
                    checked={photoConsent === "ΟΧΙ"}
                    onChange={(e) => setPhotoConsent(e.target.value)}
                  />
                  <label htmlFor="ΟΧΙ 6">{labels.no}</label>
                </InputRadioField>
              </Row>
              <Row
                marginBottom="20px"
                showErrorBorder={showErrorBorder && !accept}
              >
                <InputRadioField>
                  <input
                    type="checkbox"
                    id="accept"
                    name="accept"
                    value="ΝΑΙ"
                    checked={accept === "ΝΑΙ"}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setAccept(e.target.value);
                      } else {
                        setAccept(null);
                      }
                    }}
                  />
                  <label
                    htmlFor="accept"
                    style={{ fontSize: 16, maxWidth: "calc(100% - 30px)" }}
                  >
                    {labels.accept} {asterisk}
                  </label>
                </InputRadioField>
              </Row>
              <Row marginBottom="20px">
                <InputRadioField>
                  <input
                    type="checkbox"
                    id="notifications"
                    name="notifications"
                    value="ΝΑΙ"
                    checked={notifications === "ΝΑΙ"}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setNotifications(e.target.value);
                      } else {
                        setNotifications("ΟΧΙ");
                      }
                    }}
                  />
                  <label
                    htmlFor="notifications"
                    style={{ fontSize: 16, maxWidth: "calc(100% - 30px)" }}
                  >
                    {labels.notifications}
                  </label>
                </InputRadioField>
              </Row>
            </div>
            <br />
            <ReCAPTCHA
              ref={recaptchaRef}
              onChange={setRecaptcha}
              sitekey={RECAPTCHA_KEY}
              size={size.width > 600 ? "normal" : "compact"}
              style={{
                border:
                  showErrorBorder && !recaptcha ? "1px solid red" : undefined,
              }}
            />
            <br />
            <br />
            <div style={{ fontSize: "16px" }}>{labels.submitInfo}</div>
            <br />
            <div
              style={{
                marginTop: 20,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <SubmitBtn type="submit">Submit</SubmitBtn>
            </div>
            <br />
            <br />
          </Form>
        </FormContainer>
      </Module>
      <div style={{ width: "100%", height: 100, background: colors.orange1 }} />
      <Footer />
    </React.Fragment>
  );
}

function ParticipantsField(props) {
  function onChange(value, index, field) {
    const updatedInstances = [...props.instances];
    updatedInstances[index][field] = value;
    props.setInstances(updatedInstances);
  }

  function onAddNewClick() {
    props.setInstances([
      ...props.instances,
      {
        name: "",
        organo: "",
        other: "",
      },
    ]);
  }

  function onDelete(index) {
    if (props.instances.length > 1) {
      const updatedInstances = [...props.instances].filter(
        (a, i) => i !== index
      );
      props.setInstances(updatedInstances);
    }
  }

  return (
    <Row marginBottom="40px" showErrorBorder={props.showErrorBorder}>
      {props.instances.map((i, index) => (
        <div
          key={index}
          style={{
            gap: 10,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <InputField
            type="text"
            value={i.name}
            style={{ width: "65%" }}
            onChange={(e) => onChange(e.target.value, index, "name")}
          />
          <div
            style={{
              width: "35%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <MultiSelect
              lang={props.lang}
              data={organa.map((o, i) => o[props.lang])}
              onChange={(selections) =>
                onChange(selections.join(", "), index, "organo")
              }
            />
            {(i.organo.includes("Other (Please Specify)") ||
              i.organo.includes("Άλλο (Παρακαλώ διευκρινίστε)")) && (
              <InputField
                type="text"
                value={i.other}
                onChange={(e) => onChange(e.target.value, index, "other")}
              />
            )}
          </div>
          <div
            style={{ marginTop: 15, cursor: "pointer" }}
            onClick={() => onDelete(index)}
          >
            <CloseIcon />
          </div>
        </div>
      ))}
      <AddBtn type="button" onClick={onAddNewClick}>
        Add new
      </AddBtn>
    </Row>
  );
}

function AvailabilityHoursFields(props) {
  function onFromChange(e) {
    props.setAvailabilityFrom(e.target.value);
    const availabilityFrom = parseInt(e.target.value.replace(":", ""), 10);
    const availabilityTo =
      props.availabilityTo === "00:00"
        ? 2400
        : parseInt(props.availabilityTo.replace(":", ""), 10);

    if (
      availabilityTo - availabilityFrom < 200 &&
      !Number.isNaN(availabilityTo)
    ) {
      props.setAvailabilityTo("");
    }
  }

  function onToChange(e) {
    const availabilityFrom = parseInt(
      props.availabilityFrom.replace(":", ""),
      10
    );
    const availabilityTo =
      e.target.value === "00:00"
        ? 2400
        : parseInt(e.target.value.replace(":", ""), 10);

    if (
      availabilityTo - availabilityFrom >= 200 ||
      Number.isNaN(availabilityFrom)
    ) {
      props.setAvailabilityTo(e.target.value);
    } else {
      alert("Please select a minimum of 2-hour window of availability.");
    }
  }

  return (
    <Grid>
      <Row
        marginBottom="20px"
        showErrorBorder={props.showErrorBorder && !props.availabilityFrom}
      >
        <Label marginBottom="10px">
          {props.labels.availabilityFrom} {asterisk}
        </Label>
        <SelectField onChange={onFromChange} value={props.availabilityFrom}>
          {availabilityFrom.map((i, c) => {
            if (i === "") {
              return (
                <option key={`placeholder-${c}`} value={""} disabled>
                  {props.lang === "en"
                    ? "Please select an option"
                    : "Παρακαλώ επιλέξτε μία επιλογή"}
                </option>
              );
            }
            return (
              <option key={`${i}-from`} value={i}>
                {i}
              </option>
            );
          })}
        </SelectField>
      </Row>
      <Row
        marginBottom="20px"
        showErrorBorder={props.showErrorBorder && !props.availabilityTo}
      >
        <Label marginBottom="10px">
          {props.labels.availabilityTo} {asterisk}
        </Label>
        <SelectField onChange={onToChange} value={props.availabilityTo}>
          {availabilityTo.map((i, c) => {
            if (i === "") {
              return (
                <option key={`placeholder-${c}`} value={""} disabled>
                  {props.lang === "en"
                    ? "Please select an option"
                    : "Παρακαλώ επιλέξτε μία επιλογή"}
                </option>
              );
            }
            return (
              <option key={`${i}-to`} value={i}>
                {i}
              </option>
            );
          })}
        </SelectField>
      </Row>
    </Grid>
  );
}
