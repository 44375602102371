/* base */
import React from "react";
import axios from "axios";
import { useWindowSize } from "react-use";
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import useLocalStorageState from "use-local-storage-state";

/* assets */
import { colors } from "theme/colors";

/* components */
import { Loader } from "icons/Loader";
import { emailValidation, formatTimestamp } from "modules/sign-up";
import {
  Form,
  Grid,
  Title,
  Label,
  InputField,
  SelectField,
  FormContainer,
  Row,
  InputRadioField,
  LanguageSelector,
  SubmitBtn,
  Message,
  TextAreaField,
  Loading,
  AddBtn,
} from "modules/sign-up/styles";

const FORM_ACTION = "https://mmc-api.vercel.app/volunteer";
// const FORM_ACTION = "http://localhost:5000/volunteer";

const RECAPTCHA_KEY = "6Ldj7dwZAAAAAIuvTcDuRBwd0XUSQsYOm83u9lgp";

const texts = {
  en: {
    title: "Volunteer Form",
    description1:
      "Dear volunteer, thank you so much for your interest in joining the Make Music Cyprus 2025 festival team which will take place on Saturday 21 June 2025.",
    description2:
      "Due to the uncertainty of the pandemic restrictions, there is a possibility that the festival will be held either in person, online, or both.",
    description3: "The deadline for applications is June 1st, 2022.",
    required: "Required",
    fullname: "Full Name",
    email: "Email",
    email2: "Confirm Email",
    tel: "Contact Phone",
    age: "Age",
    interest: "I'm interested in:",
    orgName: "Name of organisation, school (if applicable)",
    city: "City of Residence",
    language: "Spoken Language(s)",
    availability: "Availability Hours",
    availabilityFrom: "Availability Hours - From",
    availabilityTo: "Availability Hours - To",
    availabilityNote: "Please select availability of at least 4 hours.",
    online:
      "If the festival is online, I would  like to volunteer wherever is needed?",
    notes: "Few words about you:",
    photoConsent:
      "I consent to photography, filming, live broadcast as well as the publication of this material. For reasons of compliance with Regulation (EU) 2016/679 of the European Parliament, on the protection of individuals with regard to the processing of personal data.",
    accept:
      "I agree to receive emails and phone texts regarding important updates or notifications regarding my application.",
    notifications:
      "I would like to receive notifications about Make Music Cyprus events and activities.",
    yes: "YES",
    no: "NO",
    whereverneeded: "Wherever Needed",
    photography: "Photography",
    videography: "Videography",
    setupcleanup: "Set-up/Clean-up",
    participantsupport: "Participant Support",
    promotion: "Festival Promotion",
    firstaid: "First Aid",
    incharge: "In charge of a music spot",
    surveillance:
      "Surveillance Measures Against the Spread of the Coronavirus (will be preceded by guidance from the organizers)",
    notsure:
      "I'm not sure. I would like you to guide me where I will be more useful.",
    interestOn: "I'm interested to help on",
    prepbefore: "Before Event Day",
    eventday: "Event Day: On Saturday June 21st 2025",
    both: "Both",
    allday: "All day",
    alsoParticipant:
      "Ι have applied / I will apply to participate in the musical part of the festival as well",
    submitInfo:
      "After you submit the form, we will contact you via email address. If you don't receive an email, please contact us.",
  },
  gr: {
    title: "Αίτηση Εθελοντή",
    description1:
      "Αγαπητέ εθελοντή, σε ευχαριστώ πολύ για το ενδιαφέρον σου να ενταχθείς στην ομάδα του φεστιβάλ Make Music Cyprus 2025 που θα πραγματοποιηθεί την Σάββατο 21 Ιουνίου 2025.",
    description2:
      "Λόγω της αβεβαιότητας των περιοριστικών μέτρων της πανδημίας, υπάρχει η πιθανότητα το φεστιβάλ να διεξαχθεί είτε με φυσική παρουσία, είτε διαδικτυακά, είτε και τα δύο.",
    description3:
      "Η τελευταία ημερομηνία υποβολής της αίτησης είναι η 1η Ιουνίου 2022.",
    required: "Υποχρεωτικό",
    fullname: "Όνομα και Επώνυμο",
    email: "Διεύθυνση ηλεκτρονικού ταχυδρομείου",
    email2: "Επαλήθευση Διεύθυνσης ηλεκτρονικού ταχυδρομείου",
    tel: "Τηλέφωνο Επικοινωνίας",
    age: "Ηλικία",
    interest: "Ενδιαφέρομαι για:",
    orgName: "Όνομα Οργανισμού, Σχολείου (αν ισχύει)",
    city: "Πόλη Διαμονής",
    language: "Γλώσσα/ες Επικοινωνίας",
    availability: "Διαθεσιμότητα",
    availabilityFrom: "Ώρες Διαθεσιμότητας - Από",
    availabilityTo: "Ώρες Διαθεσιμότητας - Μέχρι",
    availabilityNote:
      "Παρακαλούμε όπως δηλώσετε διαθεσιμότητα τουλάχιστον 4 ωρών.",
    online:
      "Στην περίπτωση που το φεστιβάλ  θα διεξαχθεί διαδικτυακά  θα ήθελα να συμμετέχω εθελοντικά σε ό,τι χρειαστεί.",
    notes: "Λίγα λόγια για εσένα:",
    photoConsent:
      "Συγκατατίθεμαι για φωτογράφιση, μαγνητοσκόπηση, ζωντανή μετάδοση καθώς και για τη δημοσίευση του υλικού αυτού. Για λόγους συμμόρφωσης με τον κανονισμό (ΕΕ) 2016/679 του Ευρωπαϊκού Κοινοβουλίου, για την προστασία των φυσικών προσώπων έναντι της επεξεργασίας των δεδομένων προσωπικού χαρακτήρα.",
    accept:
      "Αποδέχομαι να λαμβάνω μηνύματα στο ηλεκτρονικό ταχυδρομείο και στο τηλέφωνό μου που αφορούν σημαντικές ενημερώσεις ή ειδοποιήσεις σχετικά με την αίτησή μου.",
    notifications:
      "Επιθυμώ να λαμβάνω ειδοποιήσεις σχετικά με εκδηλώσεις και δραστηριότητες του Make Music Cyprus.",
    yes: "ΝΑΙ",
    no: "ΟΧΙ",
    whereverneeded: "Όπου Χρειάζεται",
    photography: "Φωτογράφιση",
    videography: "Βιντεοσκόπηση",
    setupcleanup: "Στήσιμο / Καθαριότητα",
    participantsupport: "Υποστήριξη Συμμετεχόντων",
    promotion: "Προώθηση Φεστιβάλ",
    firstaid: "Πρώτες Βοήθειες",
    incharge: "Υπεύθυνος μουσικού σημείου",
    surveillance:
      "Επιτήρηση Μέτρων Κατά της Διασποράς του Κορωνοϊού (θα προηγηθεί καθοδήγηση από τους διοργανωτές)",
    notsure:
      "Δεν είμαι σίγουρος/η. Θα ήθελα να με καθοδηγήσετε για το πού θα είμαι πιο χρήσιμος/η.",
    interestOn: "Ενδιαφέρομαι να βοηθήσω",
    prepbefore: "Πρίν την ημέρα του φεστιβάλ",
    eventday: "Ημέρα Διεξαγωγής: Σάββατο 21 Ιουνίου 2025",
    both: "Και τα δύο",
    allday: "Όλη την ήμερα",
    alsoParticipant:
      "Έχω αιτηθεί / Θα αιτηθώ για να συμμετάσχω και στο μουσικό κομμάτι του φεστιβάλ",
    submitInfo:
      "Αφού υποβάλετε τη φόρμα, θα επικοινωνήσουμε μαζί σας μέσω ηλεκτρονικού ταχυδρομείου. Αν δεν λάβετε email παρακαλώ επικοινωνήστε μαζί μας.",
  },
};

const availabilityFrom = [
  "",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
];

const availabilityTo = [
  "",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
  "00:00",
  "01:00",
  "02:00",
];

const emailMessage = {
  en: "Dear volunteer,<br/><br/>We highly appreciate your interest in participating in the Make Music Cyprus Festival 2025. We have successfully received your application and will contact you soon.<br/><br/>If you have any questions in the meantime, please don't hesitate to contact us either by replying to this email or by calling us at <a href='tel:0035770000216'>+357 700 00 216</a>.<br/><br/>Kind Regards,<br/>The Make Music Cyprus Festival Team<br/><br/>Below, you can find the information you filled in your application.",
  gr: "Αγαπητή εθελόντρια / Αγαπητέ εθελοντή,<br/><br/>Εκτιμούμε ιδιαίτερα το ενδιαφέρον σας να συνεισφέρετε στο Φεστιβάλ Make Music Cyprus 2025. Έχουμε παραλάβει την αίτηση σας επιτυχώς και θα επικοινωνήσουμε σύντομα μαζί σας!<br/><br/>Εάν στο ενδιάμεσο έχετε οποιαδήποτε απορία, μη διστάσετε να επικοινωνήσετε μαζί μας, είτε απαντώντας σε αυτό το email είτε στο τηλέφωνο <a href='tel:0035770000216'>+357 700 00 216</a>.<br/><br/>Με εκτίμηση,<br/>Ομάδα Φεστιβάλ Make Music Cyprus<br/><br/>Πιο κάτω, μπορείτε να βρείτε τα στοιχεία που συμπληρώσατε στην αίτησης σας.",
};

const yeroron = {
  en: {
    ΟΧΙ: "NO",
    ΝΑΙ: "YES",
  },
  gr: {
    ΟΧΙ: "ΟΧΙ",
    ΝΑΙ: "ΝΑΙ",
  },
};

const asterisk = <span style={{ color: colors.orange1 }}>*</span>;

export function VolunteerForm() {
  const size = useWindowSize();

  const history = useHistory();

  const recaptchaRef = React.useRef();
  const [recaptcha, setRecaptcha] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const storageLang = useLocalStorageState("lang", {
    defaultValue: "en",
  })[0];

  const [labels, setLabels] = React.useState(texts.en);
  const [lang, setLang] = React.useState(storageLang === "el" ? "gr" : "en");

  const [fullname, setFullname] = React.useState("");
  const [orgName, setOrgName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [email2, setEmail2] = React.useState("");
  const [telephone, setTelephone] = React.useState("");
  const [city, setCity] = React.useState("");
  const [language, setLanguage] = React.useState("");
  const [age, setAge] = React.useState("");
  const [interestOn, setInterestOn] = React.useState(null);
  const [interestIn, setInterestIn] = React.useState([]);
  const [availabilityFrom, setAvailabilityFrom] = React.useState("");
  const [availabilityTo, setAvailabilityTo] = React.useState("");
  const [alsoParticipant, setAlsoParticipant] = React.useState("ΟΧΙ");
  const [notes, setNotes] = React.useState("");
  const [photoConsent, setPhotoConsent] = React.useState(null);
  const [accept, setAccept] = React.useState(null);
  const [notifications, setNotifications] = React.useState("ΟΧΙ");

  const [loading, setLoading] = React.useState(false);

  const [showErrorBorder, setShowErrorBorder] = React.useState(false);

  const submitDisabled =
    fullname.length === 0 ||
    email.length === 0 ||
    !emailValidation(email) ||
    email2 !== email ||
    telephone.length === 0 ||
    !age ||
    language.length === 0 ||
    !city ||
    !interestOn ||
    interestIn.length === 0 ||
    availabilityFrom.length === 0 ||
    availabilityTo.length === 0 ||
    !photoConsent ||
    !accept ||
    !notifications ||
    !recaptcha;

  function clearAll() {
    setFullname("");
    setEmail("");
    setEmail2("");
    setInterestOn(null);
    setInterestIn([]);
    setTelephone("");
    setAge("");
    setOrgName("");
    setLanguage("");
    setCity("");
    setAvailabilityFrom("");
    setAvailabilityTo("");
    setNotes("");
    setPhotoConsent(null);
    setAccept("");
    setNotifications("");
    setShowErrorBorder(false);
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (submitDisabled) {
      setShowErrorBorder(true);
      alert("Some mandatory fields have not been filled");
      return;
    }

    setLoading(true);

    let formattedInterestOnValue = interestOn;
    switch (interestOn) {
      case texts[lang].eventday:
        formattedInterestOnValue = "Ημέρα Φεστιβάλ μόνο";
        break;
      case texts[lang].prepbefore:
        formattedInterestOnValue = "Πριν το Φεστιβάλ μόνο";
        break;
      case texts[lang].both:
        formattedInterestOnValue = "Και τα δύο";
        break;
      default:
        break;
    }

    axios
      .post(
        FORM_ACTION,
        {
          data: [
            formatTimestamp(new Date()),
            "",
            "",
            "",
            "",
            "",
            "",
            fullname,
            "",
            "",
            orgName,
            age,
            "",
            language,
            city,
            telephone,
            email,
            "",
            "",
            alsoParticipant,
            "",
            "",
            "",
            interestIn.join(", "),
            formattedInterestOnValue,
            notes,
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            `${availabilityFrom}:00`,
            `${availabilityTo}:00`,
            "",
            "",
            "Φόρμα",
            photoConsent,
            notifications,
          ],
          lang,
          emailMessage: `${emailMessage[lang]}<br/><br/><b>${
            labels.fullname
          }</b>: ${fullname}<br/><b>${labels.age}</b>: ${age}<br/><b>${
            labels.email
          }</b>: ${email}<br/><b>${labels.tel}</b>: ${telephone}<br/><b>${
            labels.city
          }</b>: ${city}<br/><b>${labels.language}</b>: ${language}<br/><b>${
            labels.orgName
          }</b>: ${orgName}<br/><b>${
            labels.interestOn
          }</b>: ${interestOn}<br/><b>${labels.interest}</b> ${interestIn.join(
            ", "
          )}<br/><b>${
            labels.availabilityFrom
          }</b>: ${availabilityFrom}<br/><b>${
            labels.availabilityTo
          }</b>: ${availabilityTo}<br/><b>${labels.notes}</b> ${notes}<br/><b>${
            labels.alsoParticipant
          }</b>: ${yeroron[lang][alsoParticipant]}<br/><b>${
            labels.photoConsent
          }</b>: ${yeroron[lang][photoConsent]}<br/><b>${labels.accept}</b>: ${
            yeroron[lang][accept]
          }<br/><b>${labels.notifications}</b>: ${
            yeroron[lang][notifications]
          }`,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setSuccess(true);
          setLoading(false);
          clearAll();
          setRecaptcha(false);
          recaptchaRef.current.reset();
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  React.useEffect(() => {
    if (storageLang) {
      setLang(storageLang === "el" ? "gr" : storageLang);
    }
  }, [storageLang]);

  return (
    <FormContainer>
      {(loading || success) && (
        <Loading>
          {loading && <Loader />}
          {success && (
            <Message color={colors.orange1}>
              Hooray! Your submission was successful!
              <br />
              <br />
              <AddBtn onClick={() => history.push("/festival")}>OK</AddBtn>
            </Message>
          )}
        </Loading>
      )}
      <Form onSubmit={handleSubmit}>
        <div
          style={{
            top: 0,
            width: "calc(100% - 10vw)",
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            justifyContent: "center",
          }}
        >
          <LanguageSelector>
            <div
              onClick={() => {
                setLabels(texts.en);
                setLang("en");
              }}
            >
              ENG
            </div>
            <span>|</span>
            <div
              onClick={() => {
                setLabels(texts.gr);
                setLang("gr");
              }}
            >
              ΕΛ
            </div>
          </LanguageSelector>
        </div>
        <Title>{labels.title}</Title>
        <br />
        <br />
        <div>
          {lang === "en" ? (
            <div style={{ fontSize: 16 }}>
              Be a part of the 7th Make Music Cyprus Festival -{" "}
              <b>Saturday, June 21, 2025!</b> A non-profit festival that spreads
              to various locations of the city of Nicosia for a day full of
              music.
              <br />
              <br />
              We are looking for volunteers for various positions and duties,
              both on the day of the festival and during the preparation in the
              previous weeks. If you would like to contribute to this unique
              festival, please apply!
              <br />
              <br />
              Feel free to contact us either by email at{" "}
              <a href="mailto:volunteer@makemusiccyprus.org">
                volunteer@makemusiccyprus.org
              </a>{" "}
              or by phone <a href="tel:0035770000216">+357 700 00 216</a> with
              any questions.
            </div>
          ) : (
            <div style={{ fontSize: 16 }}>
              ​​Γίνε και εσύ κομμάτι του 7ου Φεστιβάλ Make Music Cyprus -{" "}
              <b>Σάββατο, 21η Ιουνίου, 2025!</b> Ένα φεστιβάλ μη-κερδοσκοπικού
              χαρακτήρα το οποίο εξαπλώνεται σε διάφορα σημεία της Λευκωσίας για
              μέρα γεμάτη μουσική.
              <br />
              <br />
              Αναζητούμε εθελοντές για διάφορες θέσεις και καθήκοντα, τόσο τη
              μέρα του φεστιβάλ όσο και κατά την προετοιμασία τις προηγούμενες
              εβδομάδες. Εάν επιθυμείς και εσύ να συνεισφέρεις σε αυτό το
              μοναδικό φεστιβάλ, κάνε την αίτηση σου!
              <br />
              <br />
              Μη διστάσεις να επικοινωνήσεις μαζί μας είτε μέσω email στο{" "}
              <a href="mailto:volunteer@makemusiccyprus.org">
                volunteer@makemusiccyprus.org
              </a>{" "}
              ή στο τηλέφωνο <a href="tel:0035770000216">+357 700 00 216</a> για
              οποιεσδήποτε απορίες.
            </div>
          )}
          <br />
          <br />
          <div style={{ width: "100%", textAlign: "right", fontSize: 16 }}>
            {asterisk} {labels.required}
          </div>
          <br />
          <Row
            marginBottom="10px"
            showErrorBorder={showErrorBorder && fullname.length === 0}
          >
            <Label>
              {labels.fullname} {asterisk}
            </Label>
            <InputField
              type="text"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
            />
          </Row>
          <Row
            marginBottom="10px"
            showErrorBorder={showErrorBorder && age.length === 0}
          >
            <Label marginBottom="10px">
              {labels.age} {asterisk}
            </Label>
            <InputField
              type="number"
              value={age}
              onChange={(e) => {
                if (
                  e.target.value === 0 ||
                  e.target.value > 0 ||
                  e.target.value === ""
                ) {
                  setAge(e.target.value);
                }
              }}
            />
          </Row>
          <Row
            marginBottom="10px"
            showErrorBorder={
              showErrorBorder &&
              (email.length === 0 ||
                !emailValidation(email) ||
                email !== email2)
            }
          >
            <Label>
              {labels.email} {asterisk}
            </Label>
            <InputField
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Row>
          <Row
            marginBottom="10px"
            showErrorBorder={
              showErrorBorder &&
              (email2.length === 0 ||
                !emailValidation(email2) ||
                email !== email2)
            }
          >
            <Label>
              {labels.email2} {asterisk}
            </Label>
            <InputField
              type="text"
              value={email2}
              onChange={(e) => setEmail2(e.target.value)}
            />
          </Row>
          <Row
            marginBottom="10px"
            showErrorBorder={showErrorBorder && telephone.length === 0}
          >
            <Label>
              {labels.tel} {asterisk}
            </Label>
            <InputField
              type="number"
              value={telephone}
              onChange={(e) => setTelephone(e.target.value)}
            />
          </Row>
          <Row
            marginBottom="10px"
            showErrorBorder={showErrorBorder && city.length === 0}
          >
            <Label>
              {labels.city} {asterisk}
            </Label>
            <InputField
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </Row>
          <Row
            marginBottom="10px"
            showErrorBorder={showErrorBorder && language.length === 0}
          >
            <Label>
              {labels.language} {asterisk}
            </Label>
            <InputField
              type="text"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            />
          </Row>
          <Row marginBottom="10px">
            <Label>{labels.orgName}</Label>
            <InputField
              type="text"
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
            />
          </Row>
          <Row
            marginBottom="20px"
            showErrorBorder={showErrorBorder && !interestOn}
          >
            <Label marginBottom="10px">
              {labels.interestOn} {asterisk}
            </Label>
            <InputRadioField>
              <input
                type="radio"
                id={texts[lang].eventday}
                name="interestFor"
                value={texts[lang].eventday}
                checked={interestOn === texts[lang].eventday}
                onChange={(e) => setInterestOn(e.target.value)}
              />
              <label htmlFor={texts[lang].eventday}>{labels.eventday}</label>
            </InputRadioField>
            <InputRadioField>
              <input
                type="radio"
                id={texts[lang].prepbefore}
                name="interestFor"
                value={texts[lang].prepbefore}
                checked={interestOn === texts[lang].prepbefore}
                onChange={(e) => setInterestOn(e.target.value)}
              />
              <label htmlFor={texts[lang].prepbefore}>
                {labels.prepbefore}
              </label>
            </InputRadioField>
            <InputRadioField>
              <input
                type="radio"
                id={texts[lang].both}
                name="interestFor"
                value={texts[lang].both}
                checked={interestOn === texts[lang].both}
                onChange={(e) => setInterestOn(e.target.value)}
              />
              <label htmlFor={texts[lang].both}>{labels.both}</label>
            </InputRadioField>
          </Row>
          <Row
            marginBottom="20px"
            showErrorBorder={showErrorBorder && interestIn.length === 0}
          >
            <Label marginBottom="10px">
              {labels.interest} {asterisk}
            </Label>
            <InputRadioField>
              <input
                type="checkbox"
                id="Όπου Χρειάζεται"
                name="interest"
                value="Όπου Χρειάζεται"
                checked={interestIn.indexOf("Όπου Χρειάζεται") > -1}
                onChange={(e) => {
                  if (e.target.checked) {
                    setInterestIn([...interestIn, e.target.value]);
                  } else {
                    setInterestIn(
                      interestIn.filter((value) => value !== e.target.value)
                    );
                  }
                }}
              />
              <label htmlFor="Όπου Χρειάζεται">{labels.whereverneeded}</label>
            </InputRadioField>
            <InputRadioField>
              <input
                type="checkbox"
                id="Υπεύθυνος μουσικού σημείου"
                name="interest"
                value="Υπεύθυνος μουσικού σημείου"
                checked={interestIn.indexOf("Υπεύθυνος μουσικού σημείου") > -1}
                onChange={(e) => {
                  if (e.target.checked) {
                    setInterestIn([...interestIn, e.target.value]);
                  } else {
                    setInterestIn(
                      interestIn.filter((value) => value !== e.target.value)
                    );
                  }
                }}
              />
              <label htmlFor="Υπεύθυνος μουσικού σημείου">
                {labels.incharge}
              </label>
            </InputRadioField>
            <InputRadioField>
              <input
                type="checkbox"
                id="Φωτογράφιση"
                name="interest"
                value="Φωτογράφιση"
                checked={interestIn.indexOf("Φωτογράφιση") > -1}
                onChange={(e) => {
                  if (e.target.checked) {
                    setInterestIn([...interestIn, e.target.value]);
                  } else {
                    setInterestIn(
                      interestIn.filter((value) => value !== e.target.value)
                    );
                  }
                }}
              />
              <label htmlFor="Φωτογράφιση">{labels.photography}</label>
            </InputRadioField>
            <InputRadioField>
              <input
                type="checkbox"
                id="Βιντεοσκόπηση"
                name="interest"
                value="Βιντεοσκόπηση"
                checked={interestIn.indexOf("Βιντεοσκόπηση") > -1}
                onChange={(e) => {
                  if (e.target.checked) {
                    setInterestIn([...interestIn, e.target.value]);
                  } else {
                    setInterestIn(
                      interestIn.filter((value) => value !== e.target.value)
                    );
                  }
                }}
              />
              <label htmlFor="Βιντεοσκόπηση">{labels.videography}</label>
            </InputRadioField>
            <InputRadioField>
              <input
                type="checkbox"
                id="Στήσιμο / Καθαριότητα"
                name="interest"
                value="Στήσιμο / Καθαριότητα"
                checked={interestIn.indexOf("Στήσιμο / Καθαριότητα") > -1}
                onChange={(e) => {
                  if (e.target.checked) {
                    setInterestIn([...interestIn, e.target.value]);
                  } else {
                    setInterestIn(
                      interestIn.filter((value) => value !== e.target.value)
                    );
                  }
                }}
              />
              <label htmlFor="Στήσιμο / Καθαριότητα">
                {labels.setupcleanup}
              </label>
            </InputRadioField>
            <InputRadioField>
              <input
                type="checkbox"
                id="Υποστήριξη Συμμετεχόντων"
                name="interest"
                value="Υποστήριξη Συμμετεχόντων"
                checked={interestIn.indexOf("Υποστήριξη Συμμετεχόντων") > -1}
                onChange={(e) => {
                  if (e.target.checked) {
                    setInterestIn([...interestIn, e.target.value]);
                  } else {
                    setInterestIn(
                      interestIn.filter((value) => value !== e.target.value)
                    );
                  }
                }}
              />
              <label htmlFor="Υποστήριξη Συμμετεχόντων">
                {labels.participantsupport}
              </label>
            </InputRadioField>
            <InputRadioField>
              <input
                type="checkbox"
                id="Προώθηση Φεστιβάλ"
                name="interest"
                value="Προώθηση Φεστιβάλ"
                checked={interestIn.indexOf("Προώθηση Φεστιβάλ") > -1}
                onChange={(e) => {
                  if (e.target.checked) {
                    setInterestIn([...interestIn, e.target.value]);
                  } else {
                    setInterestIn(
                      interestIn.filter((value) => value !== e.target.value)
                    );
                  }
                }}
              />
              <label htmlFor="Προώθηση Φεστιβάλ">{labels.promotion}</label>
            </InputRadioField>
            <InputRadioField>
              <input
                type="checkbox"
                id="Πρώτες Βοήθειες"
                name="interest"
                value="Πρώτες Βοήθειες"
                checked={interestIn.indexOf("Πρώτες Βοήθειες") > -1}
                onChange={(e) => {
                  if (e.target.checked) {
                    setInterestIn([...interestIn, e.target.value]);
                  } else {
                    setInterestIn(
                      interestIn.filter((value) => value !== e.target.value)
                    );
                  }
                }}
              />
              <label htmlFor="Πρώτες Βοήθειες">{labels.firstaid}</label>
            </InputRadioField>
            <InputRadioField>
              <input
                type="checkbox"
                id="Δεν είμαι σίγουρος/η. Θα ήθελα να με καθοδηγήσετε για το πού θα είμαι πιο χρήσιμος/η."
                name="interest"
                value="Δεν είμαι σίγουρος/η. Θα ήθελα να με καθοδηγήσετε για το πού θα είμαι πιο χρήσιμος/η."
                checked={
                  interestIn.indexOf(
                    "Δεν είμαι σίγουρος/η. Θα ήθελα να με καθοδηγήσετε για το πού θα είμαι πιο χρήσιμος/η."
                  ) > -1
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    setInterestIn([...interestIn, e.target.value]);
                  } else {
                    setInterestIn(
                      interestIn.filter((value) => value !== e.target.value)
                    );
                  }
                }}
              />
              <label
                style={{ maxWidth: "calc(100% - 30px)" }}
                htmlFor="Δεν είμαι σίγουρος/η. Θα ήθελα να με καθοδηγήσετε για το πού θα είμαι πιο χρήσιμος/η."
              >
                {labels.notsure}
              </label>
            </InputRadioField>
          </Row>
          <AvailabilityHoursFields
            lang={lang}
            showErrorBorder={showErrorBorder}
            labels={labels}
            availabilityFrom={availabilityFrom}
            availabilityTo={availabilityTo}
            setAvailabilityFrom={setAvailabilityFrom}
            setAvailabilityTo={setAvailabilityTo}
          />
          <div style={{ margin: "-20px 0 20px 0", fontSize: 16 }}>
            {labels.availabilityNote}
          </div>
          <Row marginBottom="20px">
            <Label marginBottom="10px">{labels.alsoParticipant}</Label>
            <InputRadioField>
              <input
                type="radio"
                id="ΝΑΙ"
                name="alsoParticipant"
                value="ΝΑΙ"
                checked={alsoParticipant === "ΝΑΙ"}
                onChange={(e) => setAlsoParticipant(e.target.value)}
              />
              <label htmlFor="ΝΑΙ">{labels.yes}</label>
            </InputRadioField>
            <InputRadioField>
              <input
                type="radio"
                id="ΟΧΙ"
                name="alsoParticipant"
                value="ΟΧΙ"
                checked={alsoParticipant === "ΟΧΙ"}
                onChange={(e) => setAlsoParticipant(e.target.value)}
              />
              <label htmlFor="ΟΧΙ">{labels.no}</label>
            </InputRadioField>
          </Row>
          <Row marginBottom="10px">
            <Label>{labels.notes}</Label>
            <TextAreaField
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Row>
          <Row
            marginBottom="30px"
            showErrorBorder={
              showErrorBorder && (!photoConsent || photoConsent.length === 0)
            }
          >
            <Label marginBottom="10px">
              {labels.photoConsent} {asterisk}
            </Label>
            <InputRadioField>
              <input
                type="radio"
                id="ΝΑΙ"
                name="photoConsent"
                value="ΝΑΙ"
                checked={photoConsent === "ΝΑΙ"}
                onChange={(e) => setPhotoConsent(e.target.value)}
              />
              <label htmlFor="ΝΑΙ">{labels.yes}</label>
            </InputRadioField>
            <InputRadioField>
              <input
                type="radio"
                id="ΟΧΙ"
                name="photoConsent"
                value="ΟΧΙ"
                checked={photoConsent === "ΟΧΙ"}
                onChange={(e) => setPhotoConsent(e.target.value)}
              />
              <label htmlFor="ΟΧΙ">{labels.no}</label>
            </InputRadioField>
          </Row>
          <Row
            marginBottom="20px"
            showErrorBorder={
              showErrorBorder && (!accept || accept.length === 0)
            }
          >
            <InputRadioField>
              <input
                type="checkbox"
                id="accept"
                name="accept"
                value="ΝΑΙ"
                checked={accept === "ΝΑΙ"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setAccept(e.target.value);
                  } else {
                    setAccept(null);
                  }
                }}
              />
              <label
                htmlFor="accept"
                style={{ fontSize: 16, maxWidth: "calc(100% - 30px)" }}
              >
                {labels.accept} {asterisk}
              </label>
            </InputRadioField>
          </Row>
          <Row
            marginBottom="20px"
            showErrorBorder={showErrorBorder && notifications.length === 0}
          >
            <InputRadioField>
              <input
                type="checkbox"
                id="notifications"
                name="notifications"
                value="ΝΑΙ"
                checked={notifications === "ΝΑΙ"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setNotifications(e.target.value);
                  } else {
                    setNotifications("ΟΧΙ");
                  }
                }}
              />
              <label
                htmlFor="notifications"
                style={{ fontSize: 16, maxWidth: "calc(100% - 30px)" }}
              >
                {labels.notifications}
              </label>
            </InputRadioField>
          </Row>
        </div>
        <br />
        <ReCAPTCHA
          ref={recaptchaRef}
          onChange={setRecaptcha}
          sitekey={RECAPTCHA_KEY}
          size={size.width > 600 ? "normal" : "compact"}
          style={{
            border: showErrorBorder && !recaptcha ? "1px solid red" : undefined,
          }}
        />
        <br />
        <br />
        <div style={{ fontSize: "16px" }}>{labels.submitInfo}</div>
        <br />
        <div
          style={{
            marginTop: 20,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <SubmitBtn type="submit">Submit</SubmitBtn>
        </div>
        <br />
        <br />
        {success && (
          <Message color={colors.orange1}>
            Hooray! Your submission was successful!
          </Message>
        )}
      </Form>
    </FormContainer>
  );
}

function AvailabilityHoursFields(props) {
  function onFromChange(e) {
    props.setAvailabilityFrom(e.target.value);
    const availabilityFrom = parseInt(e.target.value.replace(":", ""), 10);
    let availabilityTo =
      props.availabilityTo === "00:00"
        ? 2400
        : parseInt(props.availabilityTo.replace(":", ""), 10);

    if (props.availabilityTo === "01:00") {
      availabilityTo = 2500;
    }
    if (props.availabilityTo === "02:00") {
      availabilityTo = 2600;
    }

    if (
      availabilityTo - availabilityFrom < 400 &&
      !Number.isNaN(availabilityTo)
    ) {
      props.setAvailabilityTo("");
    }
  }

  function onToChange(e) {
    const availabilityFrom = parseInt(
      props.availabilityFrom.replace(":", ""),
      10
    );
    let availabilityTo =
      e.target.value === "00:00"
        ? 2400
        : parseInt(e.target.value.replace(":", ""), 10);

    if (e.target.value === "01:00") {
      availabilityTo = 2500;
    }
    if (e.target.value === "02:00") {
      availabilityTo = 2600;
    }

    if (
      availabilityTo - availabilityFrom >= 400 ||
      Number.isNaN(availabilityFrom)
    ) {
      props.setAvailabilityTo(e.target.value);
    } else {
      alert("Please select a minimum of 4-hour window of availability.");
    }
  }

  return (
    <Grid>
      <Row
        marginBottom="20px"
        showErrorBorder={props.showErrorBorder && !props.availabilityFrom}
      >
        <Label marginBottom="10px">
          {props.labels.availabilityFrom} {asterisk}
        </Label>
        <SelectField onChange={onFromChange} value={props.availabilityFrom}>
          {availabilityFrom.map((i) => {
            if (i === "") {
              return (
                <option key="placeholder" value={""} disabled>
                  {props.lang === "en"
                    ? "Please select an option"
                    : "Παρακαλώ επιλέξτε μία επιλογή"}
                </option>
              );
            }
            return (
              <option key={`${i}-from`} value={i}>
                {i}
              </option>
            );
          })}
        </SelectField>
      </Row>
      <Row
        marginBottom="20px"
        showErrorBorder={props.showErrorBorder && !props.availabilityTo}
      >
        <Label marginBottom="10px">
          {props.labels.availabilityTo} {asterisk}
        </Label>
        <SelectField onChange={onToChange} value={props.availabilityTo}>
          {availabilityTo.map((i) => {
            if (i === "") {
              return (
                <option key="placeholder" value={""} disabled>
                  {props.lang === "en"
                    ? "Please select an option"
                    : "Παρακαλώ επιλέξτε μία επιλογή"}
                </option>
              );
            }
            return (
              <option key={`${i}-to`} value={i}>
                {i}
              </option>
            );
          })}
        </SelectField>
      </Row>
    </Grid>
  );
}
